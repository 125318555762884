import type { RouteConfigType } from '@santsg/router'
import { routes as defs } from './routes.def'

export const routes: RouteConfigType[] = [
  // PRICE SEARCH
  defs.excursionPriceSearch,

  // SERVICES > EXCURSION
  defs.excursions,
  {
    ...defs.excursion,
    children: [
      defs.excursionDetails,
      defs.excursionMedia,
      defs.excursionItinerary,
      defs.excursionServiceItemsAndAncillaries,
      defs.excursionSalePrices,
      defs.excursionRestrictions,
      defs.excursionWarningMessages,
    ],
  },
  defs.excursionManagementMonitor,
  defs.serviceItemsAndAncillaries,
  {
    ...defs.serviceItemAndAncillary,
    children: [defs.serviceItemAndAncillaryDetails, defs.serviceItemAndAncillaryPrices],
  },
  defs.excursionPackage,
  defs.excursionPackageCreate,
  defs.excursionPackageEdit,
  defs.infoCocktail,
  defs.guideCommissions,

  //REPORTS > EXCURSION REPORTS

  defs.excursionAccountControl,

  // MASTER DATA > EXCURSION DEFINITIONS
  defs.excursionVariations,
  defs.excursionFacilities,
  defs.excursionThemes,
  defs.guideTypes,
  defs.introductionHeaders,
  defs.guides,
  {
    ...defs.guide,
    children: [defs.guideDetails, defs.guideSettings],
  },
  {
    ...defs.assignment,
    children: [defs.hotelAssignment, defs.excursionAssignment],
  },
  defs.pickupTimes,
]
