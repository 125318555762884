import { routes as main } from '@/_main_/routes.config'
import { routes as excursion } from '@/_excursion_/routes.config'
import { routes as transfer } from '@/_transfer_/routes.config'
import { routes as hotel } from '@/_hotel_/routes.config'
import { routes as booking } from '@/_booking_/routes.config'
import { routes as auth } from '@/_auth_/routes.config'
import { routes as flight } from '@/_flight_/routes.config'

const authRoutes = [...auth]

const userRoutes = [...main, ...excursion, ...transfer, ...hotel, ...booking, ...flight]

if (import.meta.env.DEV) {
  userRoutes.push({
    name: 'dev',
    path: '/dev',
    importer: () => import('@/dev/page'),
  })
}

export { authRoutes, userRoutes }
