import * as Sentry from '@sentry/react'
import { getSession } from '@santsg/common'

if (process.env.NODE_ENV === 'production') {
  const environment = import.meta.env.VITE_ENV || 'unknown'

  Sentry.init({
    dsn: 'https://23cff84bc56dc196000f2dff998cc927@o4508522751197184.ingest.de.sentry.io/4508522755653712',
    environment,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https:\/\/dev\.sanoctopus\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  const user = getSession()?.user

  if (user) {
    Sentry.setUser({
      id: user.userId,
      email: user.email,
      username: user.email,
    })
  } else {
    Sentry.setUser(null)
  }

  console.info('OCTOPUS', `sentry initialized for "${environment.toUpperCase()}" environment`)
}
