import { defineRoute } from '@santsg/router'

const routes = {
  /* ======================================================
   * SERVICES > FLIGHT
   * ======================================================
   */

  flights: defineRoute({
    path: '/flight/list',
    importer: () => import('@/_flight_/app/flights/page'),
  }),

  flight: defineRoute({
    path: '/flight/list/:id',
    importer: () => import('@/_flight_/app/flights/[id]/layout'),
  }),

  flightDetails: defineRoute({
    path: '/flight/list/:id/details',
    importer: () => import('@/_flight_/app/flights/[id]/details/page'),
  }),

  flightDays: defineRoute({
    initialQueryParams: () => {
      return {
        sort: 'date asc',
      }
    },
    path: '/flight/list/:id/flight-days',
    importer: () => import('@/_flight_/app/flights/[id]/flight-days/page'),
  }),

  /* ======================================================
   * MASTER DATA > FLIGHT DEFINITIONS
   * ======================================================
   */

  airports: defineRoute({
    path: '/flight/definition/airports',
    importer: () => import('@/_flight_/app/airports/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
  routes[name as RouteNameType].tags = ['flight']
})

export { routes }
