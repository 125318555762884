import type { RouteConfigType } from '@santsg/router'
import { routes as defs } from './routes.def'

export const routes: RouteConfigType[] = [
  defs.HOTEL_hotels,
  {
    ...defs.HOTEL_hotel,
    children: [
      defs.HOTEL_hotel_details,
      defs.HOTEL_hotel_contacts,
      defs.HOTEL_hotel_bankAccounts,
      defs.HOTEL_hotel_messages,
      defs.HOTEL_hotel_mediaSeasons,
      {
        ...defs.HOTEL_hotel_mediaSeason,
        children: [
          defs.HOTEL_hotel_mediaSeason_multimedia,
          defs.HOTEL_hotel_mediaSeason_hotelFacilities,
          defs.HOTEL_hotel_mediaSeason_roomFacilities,
          defs.HOTEL_hotel_mediaSeason_nearbyLocations,
        ],
      },
      defs.HOTEL_hotel_contracts,
      defs.HOTEL_hotel_contracts_create,
      {
        ...defs.HOTEL_hotel_contract,
        children: [
          defs.HOTEL_hotel_contract_detail,
          defs.HOTEL_hotel_contract_periods,
          defs.HOTEL_hotel_contract_buyer_nationalities,
          defs.HOTEL_hotel_contract_occupancyRules,
        ],
      },
    ],
  },
  // MASTER DATA > HOTEL DEFINITIONS
  defs.HOTEL_themes,
  defs.HOTEL_hotelCategories,
  defs.HOTEL_facilityCategories,
  defs.HOTEL_introductionHeaders,
  defs.HOTEL_contractSeasons,
  defs.HOTEL_propertyTypes,
  defs.HOTEL_roomViewTypes,
  defs.HOTEL_facilities,
  defs.HOTEL_boardTypes,
  defs.HOTEL_supplements,
  defs.HOTEL_roomFacilities,
  defs.HOTEL_roomTypes,
  defs.HOTEL_ancillaries,
]
