import { setCoreConfig } from '@santsg/config'

if (import.meta.env.VITE_API_BASE) {
  setCoreConfig('API_BASE', import.meta.env.VITE_API_BASE)
}

if (import.meta.env.VITE_FILES_BASE) {
  setCoreConfig('FILES_BASE', import.meta.env.VITE_FILES_BASE)
}

if (import.meta.env.VITE_QUERY_DEVTOOLS === 'yes') {
  setCoreConfig('QUERY_DEVTOOLS', true)
}

setCoreConfig('QUERY_CLIENT_CONFIG', {
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      staleTime: 60_000,
    },
  },
})
