import { dayjs, formatDateForApi } from '@santsg/common'
import { defineRoute } from '@santsg/router'

const routes = {
  /* ======================================================
   * SERVICES > TRANSFER
   * ======================================================
   */

  transfers: defineRoute({
    path: '/transfer/list',
    importer: () => import('@/_transfer_/app/transfers/page'),
  }),

  transfer: defineRoute({
    path: '/transfer/list/:id',
    importer: () => import('@/_transfer_/app/transfers/[id]/layout'),
  }),

  transferDetails: defineRoute({
    path: '/transfer/list/:id/details',
    importer: () => import('@/_transfer_/app/transfers/[id]/details/page'),
  }),

  transferMedia: defineRoute({
    path: '/transfer/list/:id/media',
    importer: () => import('@/_transfer_/app/transfers/[id]/media/page'),
  }),

  transferPrices: defineRoute({
    path: '/transfer/list/:id/prices',
    importer: () => import('@/_transfer_/app/transfers/[id]/transfer-prices/page'),
  }),

  transferAncillaryPrices: defineRoute({
    path: '/transfer/list/:id/ancillary-prices',
    importer: () => import('@/_transfer_/app/transfers/[id]/ancillary-prices/page'),
  }),

  transferRestrictions: defineRoute({
    path: '/transfer/list/:id/restrictions',
    importer: () => import('@/_transfer_/app/transfers/[id]/restrictions/page'),
  }),

  transferWarningMessages: defineRoute({
    path: '/transfer/list/:id/warning-messages',
    importer: () => import('@/_transfer_/app/transfers/[id]/warning-messages/page'),
  }),

  transferAncillaries: defineRoute({
    path: '/transfer/ancillaries',
    importer: () => import('@/_transfer_/app/ancillaries/page'),
  }),

  /* ======================================================
   * MASTER DATA > TRANSFER DEFINITIONS
   * ======================================================
   */

  operationalRegionGroups: defineRoute({
    path: '/transfer/definition/operational-region-groups',
    importer: () => import('@/_transfer_/app/operational-region-groups/page'),
  }),

  transferCostItems: defineRoute({
    path: '/transfer/definition/transfer-cost-items',
    importer: () => import('@/_transfer_/app/cost-items/page'),
  }),

  transferTypes: defineRoute({
    path: '/transfer/definition/transfer-types',
    importer: () => import('@/_transfer_/app/transfer-types/page'),
  }),

  vehicleTypes: defineRoute({
    path: '/transfer/definition/vehicle-types',
    importer: () => import('@/_transfer_/app/vehicle-types/page'),
  }),

  drivers: defineRoute({
    path: '/transfer/definition/drivers',
    importer: () => import('@/_transfer_/app/drivers/page'),
  }),

  vehicles: defineRoute({
    path: '/transfer/definition/vehicles',
    importer: () => import('@/_transfer_/app/vehicles/page'),
  }),

  transferIntroductionHeaders: defineRoute({
    path: '/transfer/definition/introduction-headers',
    importer: () => import('@/_transfer_/app/introduction-headers/page'),
  }),

  /* ======================================================
   * PRICE SEARCH
   * ======================================================
   */

  transferPriceSearch: defineRoute({
    path: '/transfer/price-search',
    importer: () => import('@/_transfer_/app/price-search/page'),
  }),

  /* ======================================================
   * OPERATIONS
   * ======================================================
   */

  vehicleOperations: defineRoute({
    initialQueryParams: () => {
      const tomorrow = dayjs().add(1, 'day')
      return {
        targetStartDate: formatDateForApi(tomorrow.startOf('day')),
        targetEndDate: formatDateForApi(tomorrow.endOf('day')),
      }
    },
    path: '/vehicle/operations',
    importer: () => import('@/_transfer_/app/vehicle-operations/page'),
    // rememberUserPreferences: true,
    // redirectByUserPreferredFilters: true,
  }),

  vehicleManagement: defineRoute({
    initialQueryParams: () => {
      const tomorrow = dayjs().add(1, 'day')
      return {
        targetDateFrom: formatDateForApi(tomorrow.startOf('day')),
        targetDateTo: formatDateForApi(tomorrow.endOf('day')),
      }
    },
    path: '/transfer/vehicle-management',
    importer: () => import('@/_transfer_/app/vehicle-management/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
  routes[name as RouteNameType].tags = ['transfer']
})

export { routes }
