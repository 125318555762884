import { defineRoute } from '@santsg/router'
import { dayjs, formatDateForApi } from '@santsg/common'

const routes = {
  booking: defineRoute({
    path: '/booking/transaction/:transactionId',
    importer: () => import('@/_booking_/app/book/[transactionId]/page'),
  }),

  bookingResult: defineRoute({
    path: '/booking/result/:bookingNumber',
    importer: () => import('@/_booking_/app/booking-result/[bookingNumber]/page'),
  }),

  bookingMonitor: defineRoute({
    path: '/booking/monitor',
    importer: () => import('@/_booking_/app/booking-monitor/page'),
    initialQueryParams: () => ({
      saleDateFrom: formatDateForApi(dayjs().startOf('day')),
      saleDateTo: formatDateForApi(dayjs().endOf('day')),
    }),
  }),

  bookingDetail: defineRoute({
    path: '/booking/monitor/:id',
    importer: () => import('@/_booking_/app/booking-monitor/[id]/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
  routes[name as RouteNameType].tags = ['booking']
})

export { routes }
